html {
    position: relative;
    min-height: 100%;
}

html, body {
    margin: 0;
    padding: 0;
}

/*
    Combined Sections
*/
div.banner, div.card-body, div.footer {
    background-color: #b5d4ff;
}

div.banner, div.logo-container {
    margin-bottom: 2.5rem;
}

.row {
    margin: 0;
}

/*
    Banner
*/
div.banner {
    text-align: center;
    padding: 25px 0 10px 0;
}

div.banner a {
    color: black;
    text-decoration: none;
}

div.banner p {
    margin-bottom: 0.5rem;
}

/*
    Logo Container
*/
div.logo-container div {
    align-items: center;
    margin-left: auto;
}

button.login, button.login:hover, button.login:focus {
    font-weight: bold;
    background-color: #2561b3;
    color: #ffffff;
    border-color: #2561b3;
}

button.login:active {
    background-color: #0e50ad !important;
    color: #ffffff !important;
    border-color: #0e50ad !important;
}

/*
    Filters
*/
div.filters-container {
    margin-bottom: 1rem;
    font-size: 12px;
}

div.filters-container * {
    font-size: 0.8rem !important;
}

button.search, button.search:hover, button.search:focus {
    font-weight: bold;
    background-color: #b5d4ff;
    color: #7e7d7d;
    border-color: #b5d4ff;
}

button.search:active {
    background-color: #9cc3fa !important;
    color: #7e7d7d !important;
    border-color: #9cc3fa !important;
}

button.reset, button.reset:hover, button.reset:focus {
    font-weight: bold;
    background-color: #6e92c4;
    color: #ffffff;
    border-color: #6e92c4;
}

button.reset:active {
    background-color: #5682c0 !important;
    color: #ffffff !important;
    border-color: #5682c0 !important;
}

/*
    Hospital Card
*/
div.card {
    margin-bottom: 1.5rem;
}

div.card-body {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

div.card-body span.title {
    color: #7e7d7d;
    font-size: 14px;
}

div.card-body span {
    display: block;
}

div.card-body span.title, div.card-body span.value {
    font-weight: bold;
}

div.card-footer {
    padding: 0;
    background-color: white;
    border-top: 0;
    font-size: 14px;
}

div.card-footer span.title {
    margin-right: 10px;
    font-weight: bold;
}

button.toggle-button {
    padding: 1px 6px;
}

/*
    Pagination
*/
ul.pagination {
    justify-content: center;
}

/*
    Countdown Timer
*/
div.countdown-container {
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 1000;
    height: 75px;
    width: 75px;
}

/*
    Login
*/
button.login a {
    color: white;
    text-decoration: none;
}

/*
    Footer
*/
div.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 75px;
}

div.footer div {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

/* For small screen */
@media (max-width: 768px) {
    .row-group .col-group:nth-of-type(even){
        background-color: #e3eeff;
    }
    .row-group .col-group:nth-of-type(odd){
        background-color: white;
    }
}